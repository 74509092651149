import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';
import ModalStore from './ModalStore';
import ConfirmModalStore from './ConfirmModalStore';
import ProgressStore from './ProgressStore';
import CustSearchPopupStore from './CustSearchPopupStore';
import UserPopupStore from './UserPopupStore';
import ApiOrdeItemAllocPopupStore from './ApiOrdeItemAllocPopupStore';
import StockPopupStore from './StockPopupStore';
import IfrsPopupStore from './IfrsPopupStore';
import PostPopupStore from './PostPopupStore';
import LargeInputNoPopupStore from './LargeInputNoPopupStore';
import RentalOrderDividePopupStore from './RentalOrderDividePopupStore';
import KYContInfoCheckPopupStore from './KYContInfoCheckPopupStore';
import ItemPopupStore from './ItemPopupStore';
import FilePopupStore from './FilePopupStore';
import AssetInfoChangePopStore from './AssetInfoChangePopStore';
import AssetHistoryPopStore from './AssetHistoryPopStore';
import AssetReturnPopStore from './AssetReturnPopStore';
import AssetListChangePopStore from './AssetListChangePopStore';
import PreAssetSpecPopStore from './PreAssetSpecPopStore';
import RentalOrderPopStore from './RentalOrderPopStore';
import RentalOrderItemCombPopStore from './RentalOrderItemCombPopStore';
import KYUseageDaysPopStore from './KYUseageDaysPopStore';
import ApiMisreturnSearchPopupStore from './ApiMisreturnSearchPopupStore';
import KYContRenewalPopupStore from './KYContRenewalPopupStore';
import SyncOrderPopupStore from './SyncOrderPopupStore';

const CommonStore = {
	ModalStore,
	ConfirmModalStore,
	ProgressStore,
	CustSearchPopupStore,
	UserPopupStore,
	ApiOrdeItemAllocPopupStore,
	StockPopupStore,
	IfrsPopupStore,
	PostPopupStore,
	LargeInputNoPopupStore,
	RentalOrderDividePopupStore,
	KYContInfoCheckPopupStore,
	ItemPopupStore,
	FilePopupStore,
	AssetInfoChangePopStore,
	AssetHistoryPopStore,
	AssetReturnPopStore,
	AssetListChangePopStore,
	PreAssetSpecPopStore,
	RentalOrderPopStore,
	RentalOrderItemCombPopStore,
	KYUseageDaysPopStore,
	ApiMisreturnSearchPopupStore,
	KYContRenewalPopupStore,
	SyncOrderPopupStore,
};
export default CommonStore;
