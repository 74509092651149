import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Transition, ArrayTextComma, errorMsgCheck } from '../../common/ComFunction';

import { SyncOrder } from '../../service/rental/RentalOrderService'; //FIXME : backend개발 후 서비스 연결 진행

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
const { SyncOrderPopupStore, ConfirmModalStore, ModalStore, ProgressStore } = CommonStore;
class SyncOrderPopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contNo: '',
			ordeNo: props.ordeNo,
		};
	}
uu
	handleClose = () => {
		SyncOrderPopupStore.closePopup();
		this.setState({
			contNo: '',
		});
	};

	render() {
		return (
			<div>
				<Dialog open={SyncOrderPopupStore.open} onClose={this.handleClose} TransitionComponent={Transition}>
					<div style={{ padding: '5px 5px 5px 5px' }}>
						<span style={{ marginLeft: 10 }}>요청번호(필수)</span>
						<DialogContent>
							<input
								type="text"
								style={{ minHeight: 10, width: 250, marginTop: 10 }}
								value={SyncOrderPopupStore.ordeNo}
								readOnly={true}
								// onChange={(e) => this.setState({ oldCustReqNo: e.target.value })}
							/>
						</DialogContent>
						<span style={{ marginLeft: 10 }}>계약번호(필수)</span>
						<DialogContent>
							<input
								type="text"
								style={{ minHeight: 10, width: 250, marginTop: 10 }}
								value={this.state.contNo}
								onChange={(e) => this.setState({ contNo: e.target.value })}
							/>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => ConfirmModalStore.openModal('주문 동기화하시겠습니까?', this.SyncOrder)} color="primary" autoFocus>
								매칭
							</Button>
							<Button onClick={() => this.handleClose()} color="secondary">
								닫기
							</Button>
						</DialogActions>
					</div>
				</Dialog>
			</div>
		);
	}

	SyncOrder = () => {
		//let arrData = window.getAssetInfoChangeList();
		let param = {
			contNo: this.state.contNo,
			ordeNo: SyncOrderPopupStore.ordeNo,
		};
		console.log(param.ordeNo);
		console.log(param.contNo);

		if (param.contNo === '') {
			ModalStore.openModal('매칭할 계약번호를 입력하세요');
			return;
		}else {
		    ProgressStore.activeProgress();
		}

		SyncOrder(param)
			.then((r) => {
				console.log(r);
				if(r[0].successYn == "Y") {
				    ModalStore.openModal('처리 되었습니다.', this.handleClose());
				} else{
				    ModalStore.openModal('주문동기화는 완료되었지만, IAS DB과 연동에 실패하였습니다.', this.handleClose());
				}
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				ProgressStore.deactiveProgress();
			});
	};
}
export default observer(SyncOrderPopup);
