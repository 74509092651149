import React, { Component } from 'react';
import Header from '../include/Header';
import LeftMenu from '../include/LeftMenu';
import IBSheet from '../IBSheet';

import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ReturnIcon from '@material-ui/icons/LocalShipping';
import CancelIcon from '@material-ui/icons/HighlightOff';
import Button from '@material-ui/core/Button';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
//날짜
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';

import Tooltip from '@material-ui/core/Tooltip';

import { getAssetReturnAskList, updateAssetReturnAsk } from '../../service/rental/RentalAssetService';
import { getOrderAppStatus } from '../../service/rental/RentalOrderService';
import { getCisCommCode, getCustomData } from '../../service/CommonService';
import { dateToFormat, errorMsgCheck, resultToIbSheet, InsertComma, getHostType } from '../../common/ComFunction';

import { observer } from 'mobx-react';
import CommonStore from '../../common/CommonStore';
import { CUST_NO_KY_DEV, CUST_NO_KY } from '../../common/ComValues';
const { ModalStore, ConfirmModalStore, ProgressStore, CustSearchPopupStore, UserPopupStore, KYContInfoCheckPopupStore } = CommonStore;

class ReturnAsk extends Component {
	constructor(props) {
		super(props);

		this.state = {
			sCustNo: sessionStorage.authCode > 1 ? sessionStorage.custNo : '',
			sCustNm: sessionStorage.authCode > 1 ? sessionStorage.custNm : '',
			sReturnStatus: '',
			sAskUserId: '',
			sAskUserNm: '',
			sUserChgId: sessionStorage.authCode > 1 ? '' : sessionStorage.userId,
			sUserChgNm: sessionStorage.authCode > 1 ? '' : sessionStorage.userName,
			sAsstNo: '',

			delMethodList: [],
			returnStatusList: [],
			centerCodeList: [],
			appList: [],

			/* 필드데이터 */
			returnAskNo: '',
			returnStatusCd: 1,
			returnStatus: '',
			custNo: '',
			custNm: '',
			cancelFee: '',
			asstNo: '',
			viewAsstNo: '', /*추가*/
			itemId: '',
			contNo: '',
			askUser: '',
			returnHopeDate: '',
			returnRmk: '',
			postCode: '',
			postAddr: '',
			postSddr: '',
			addrUser: '',
			addrTelno: '',
			addrHpno: '',
			delRmk: '',
			//delMethod: '',
			delRealDate: '',
			askRmk: '',
			cancelRmk: '',
			custReqNo: '', // api v2 고객요청번호

			/* 회수정보 */
			delMethod: '',
			delDate: moment(),
			delTime: '',
			delTimeClas: '',
			centerCd: '',

			/* 시스템정보 */
			disabled: true,

			contInfoBtnShow: 'none', // 계약정보확인 버튼 활성화 여부
		};

		this.sheetData = {
			id: 'returnAskSheet', // sheet id
			initData: 'rental/Init-ReturnAsk', // sheet initData javascript filename
			//data: "data", // sheet data javascript filename
			width: '100%', // sheet width
			height: '587px', // sheet height
		};
	}

	componentDidMount = () => {
		Promise.all([getCisCommCode('04'), getCisCommCode('14'), getCisCommCode('30')])
			.then((r) => {
				let [method, status, centerCode] = r;

				this.setState({
					delMethodList: method,
					returnStatusList: status,
					centerCodeList: centerCode, 
				});
			})
			.catch((err) => {
				errorMsgCheck(err);
			});
	};

	componentWillUnmount = () => {
		window[this.sheetData.id].DisposeSheet();
	};

	getReturnAskList = () => {
		// 대표고객이면서 고객관리자인 경우 현재 대표고객으로 조회시 하위 고객사까지 조회 가능하다
		var repYn = '';
		if (sessionStorage.custNo === sessionStorage.repCustNo && sessionStorage.custNo === this.state.sCustNo && sessionStorage.authCode === '2') {
			repYn = 'Y';
		} else {
			repYn = 'N';
		}

		let param = {
			returnStatus: this.state.sReturnStatus,
			custNo: this.state.sCustNo,
			asstNo: this.state.sAsstNo,
			askUser: this.state.sAskUserId,
			userChg: this.state.sUserChgId,
			repYn: repYn, // 대표고객이 하위 고객사 전체 조회 가능함 20190716 kkj
		};

		ProgressStore.activeProgress();
		getAssetReturnAskList(param)
			.then((r) => {
				window[this.sheetData.id].LoadSearchData(resultToIbSheet(r));
				this.setEmptyField(); //초기화
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	getReturnAskDetail = () => {
		if (!this.state.returnAskNo) return;

		let param = {
			returnAskNo: this.state.returnAskNo,
			//returnStatus: this.state.sReturnStatus,
			custNo: this.state.sCustNo,
			asstNo: this.state.sAsstNo,
			askUser: this.state.sAskUserId,
			userChg: this.state.sUserChgId,
		};

		ProgressStore.activeProgress();
		getAssetReturnAskList(param)
			.then((r) => {
				this.setDetailData(r[0]);
				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	cancelReturnAsk = () => {
		let param = {
			returnAskNo: this.state.returnAskNo,
			returnStatus: '4', //취소
			cancelRmk: this.state.cancelRmk,
		};

		ProgressStore.activeProgress();
		updateAssetReturnAsk(param)
			.then(() => {
				ModalStore.openModal('취소 되었습니다.');
				ProgressStore.deactiveProgress();
			})
			.then(() => {
				//재조회
				this.getReturnAskList();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	returnAsk = () => {
		if (!this.state.returnAskNo) {
			ModalStore.openModal('재조회하여 다시 요청해 주세요.');
			return;
		}

		let param = {
			custNo: this.state.custNo,
			deptCode: '04', //OA를 기본으로 한다. 추후 팀 추가되면 시스템화 하는걸로
			contNo: this.state.contNo,
			asstNo: this.state.asstNo,
			itemId: this.state.itemId,
			returnAskNo: this.state.returnAskNo,
			returnStatus: '2', //반납접수
			delMethod: this.state.delMethod,
			delRealDate: this.state.delDate.format('YYYY-MM-DD'),
			delTime: this.state.delTime,
			delTimeClas: this.state.delTimeClas,
			askRmk: this.state.askRmk,
			cancelFee: this.state.cancelFee,
			addrSeq: sessionStorage.addrSeq,
			centerCd: this.state.centerCd,
		};

		ProgressStore.activeProgress();
		updateAssetReturnAsk(param)
			.then(() => {
				ModalStore.openModal('회수의뢰가 완료 되었습니다.');
				ProgressStore.deactiveProgress();
			})
			.then(() => {
				//재조회
				this.getReturnAskList();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	cancelHandle = () => {
		if (!this.state.cancelRmk) {
			ModalStore.openModal('반납 취소 사유를 입력해 주세요.');
			return;
		}

		ConfirmModalStore.openModal('반납요청을 취소 하시겠습니까?', this.cancelReturnAsk);
	};

	returnAskHandle = () => {
		if (!this.state.delMethod) {
			ModalStore.openModal('회수방법을 선택해 주세요.');
			return;
		}
		if (!this.state.delDate) {
			ModalStore.openModal('회수일자를 지정해 주세요.');
			return;
		}
		if (!this.state.delTimeClas) {
			ModalStore.openModal('회수일자 시간유형을 선택해 주세요.');
			return;
		}
		if (this.state.delTimeClas == '04' && !this.state.delTime) {
			ModalStore.openModal('회수일자 시간을 선택해 주세요.');
			return;
		}
		if (!this.state.centerCd) {
			ModalStore.openModal('센터를 선택해 주세요.');
			return;
		}

		ConfirmModalStore.openModal('회수의뢰를 진행 하시겠습니까?', this.returnAsk);
	};

	getReturnAskData = () => {
		if (window[this.sheetData.id].LastRow() === 0) return;

		let data = window.getReturnAskSelectData();
		if (data.returnAskNo === undefined) return;

		this.setDetailData(data);

		//결재정보를 가져온다.
		ProgressStore.activeProgress();
		getOrderAppStatus(data.returnAskNo)
			.then((r) => {
				this.setState({
					appList: r,
				});

				ProgressStore.deactiveProgress();
			})
			.catch((err) => {
				errorMsgCheck(err, ProgressStore.deactiveProgress());
			});
	};

	setDetailData = (data) => {
		// console.log(data);
		this.setState(
			{
				returnAskNo: data.returnAskNo,
				returnStatusCd: data.returnStatusCd,
				returnStatus: data.returnStatus,
				custNo: data.custNo,
				custNm: data.custNm,
				cancelFee: data.cancelFee,
				asstNo: data.asstNo,
				viewAsstNo: data.viewAsstNo,
				itemId: data.itemId,
				contNo: data.contNo,
				askUser: data.askUser,
				returnHopeDate: data.returnHopeDate,
				returnRmk: data.returnRmk,
				postCode: data.postCode,
				postAddr: data.postAddr,
				postSddr: data.postSddr,
				addrUser: data.addrUser,
				addrTelno: data.addrTelno,
				addrHpno: data.addrHpno,
				delRmk: data.delRmk,
				delMethod: data.delMethod,
				delRealDate: data.delRealDate,
				delTime: data.delTime,
				delTimeClas: data.delTimeClas,
				askRmk: !data.askRmk ? data.delRmk : data.askRmk,
				cancelRmk: data.cancelRmk,
				disabled: !['0', '1', '2'].includes(data.returnStatusCd),
				custReqNo: data.custReqNo, // api v2 고객요청번호
				centerCd: data.centerCd,
			},
			() => {
				this.kyContInfoCheckBtnShowControl();
			},
		);
	};

	setEmptyField = () => {
		this.setState({
			returnAskNo: '',
			returnStatusCd: 1,
			returnStatus: '',
			custNo: '',
			custNm: '',
			cancelFee: '',
			asstNo: '',
			viewAsstNo: '',
			itemId: '',
			contNo: '',
			askUser: '',
			returnHopeDate: '',
			returnRmk: '',
			postCode: '',
			postAddr: '',
			postSddr: '',
			addrUser: '',
			addrTelno: '',
			addrHpno: '',
			delRmk: '',
			delMethod: '',
			delRealDate: '',
			delTime: '',
			delTimeClas: '',
			askRmk: '',
			cancelRmk: '',
			custReqNo: '',
			disabled: true,
			centerCd: '',
		});
	};

	delMethodName = (delType) => {
		if (!delType) return;

		let name = '';
		let methodList = this.state.delMethodList;

		for (var i in methodList) {
			if (methodList[i].value === delType) {
				name = methodList[i].title;
			}
		}

		return name;
	};

	dateHandler = (date) => {
		this.setState({ delDate: date });
	};

	customerSearch = () => {
		CustSearchPopupStore.openPopup('1', null, null, this.handleCustSearch);
	};

	handleCustSearch = (item) => {
		if (item !== undefined) {
			this.setState({
				sCustNo: item.custNo,
				sCustNm: item.custNm,
			});
		}
	};

	userSearch = (type) => {
		UserPopupStore.openPopup(type, this.state.sCustNo, this.handleUserSearch);
	};

	handleUserSearch = (item) => {
		if (item !== undefined) {
			if (UserPopupStore.type === 'user') {
				this.setState({
					sUserChgId: item.userCode,
					sUserChgNm: item.userName,
				});
			} else {
				this.setState({
					sAskUserId: item.userId,
					sAskUserNm: item.userName,
				});
			}
		}
	};

	makeUserChgInput = () => {
		if (sessionStorage.authCode > '1') {
			return (
				<tr>
					<th>신청자</th>
					<td>
						<input
							type="text"
							style={{ width: 100, marginRight: 10 }}
							value={this.state.sAskUserId}
							placeholder="ID"
							onChange={(e) => this.setState({ sAskUserId: e.target.value })}
						/>
						<IconButton onClick={() => this.userSearch('')} color="secondary" className="iconButton">
							<SearchIcon fontSize="small" />
						</IconButton>
						<input
							type="text"
							style={{ width: 80, marginLeft: 10 }}
							value={this.state.sAskUserNm}
							placeholder="이름"
							onChange={(e) => this.setState({ sAskUserNm: e.target.value })}
						/>
					</td>
					<td />
					<td />
				</tr>
			);
		} else {
			return (
				<tr>
					<th>신청자</th>
					<td>
						<input
							type="text"
							style={{ width: 100, marginRight: 10 }}
							value={this.state.sAskUserId}
							placeholder="ID"
							onChange={(e) => this.setState({ sAskUserId: e.target.value })}
						/>
						<IconButton onClick={() => this.userSearch('')} color="secondary" className="iconButton">
							<SearchIcon fontSize="small" />
						</IconButton>
						<input
							type="text"
							style={{ width: 80, marginLeft: 10 }}
							value={this.state.sAskUserNm}
							placeholder="이름"
							onChange={(e) => this.setState({ sAskUserNm: e.target.value })}
						/>
					</td>
					<th>지원담당자</th>
					<td colSpan="3">
						<input
							type="text"
							style={{ width: 100, marginRight: 10 }}
							value={this.state.sUserChgId}
							placeholder="ID"
							onChange={(e) => this.setState({ sUserChgId: e.target.value })}
						/>
						<IconButton onClick={() => this.userSearch('user')} color="secondary" className="iconButton">
							<SearchIcon fontSize="small" />
						</IconButton>
						<input
							type="text"
							style={{ width: 80, marginLeft: 10 }}
							value={this.state.sUserChgNm}
							placeholder="이름"
							onChange={(e) => this.setState({ sUserChgNm: e.target.value })}
						/>
					</td>
				</tr>
			);
		}
	};

	contInfoCheck = () => {
		KYContInfoCheckPopupStore.openPopup(this.state.custNo, this.state.custReqNo);
	};

	kyContInfoCheckBtnShowControl = () => {
		getCustomData({ custNo: this.state.custNo, customCode: 'CT033' }).then((r) => {
			if (r.length > 0) {
				this.setState({
					contInfoBtnShow: r[0].customWord,
				});
			} else {
				this.setState({
					contInfoBtnShow: 'none',
				});
			}
		});
	};

	changeReturnReasonInput = (returnStatusCd) => {
		if (returnStatusCd === '3') {
			return (
				<tr>
					<th style={{ color: '#E91E63' }}>회수확정일자</th>
					<td>
						<input type="text" readOnly={true} value={dateToFormat(this.state.delRealDate) + ' (' + this.state.delTime + '시)'} />
					</td>
					<th style={{ color: '#E91E63' }}>회수방법</th>
					<td>
						<input type="text" readOnly={true} value={this.delMethodName(this.state.delMethod)} />
					</td>
				</tr>
			);
		} else if (returnStatusCd === '2') {
			return (
				<>
					<tr>
						<th style={{ color: '#E91E63' }}>회수확정일자</th>
						<td>
							<input type="text" readOnly={true} value={dateToFormat(this.state.delRealDate) + ' (' + this.state.delTime + '시)'} />
						</td>
						<th style={{ color: '#E91E63' }}>회수방법</th>
						<td>
							<input type="text" readOnly={true} value={this.delMethodName(this.state.delMethod)} />
						</td>
					</tr>
					<tr>
						<th>반납취소사유</th>
						<td colSpan="3">
							<input
								type="text"
								readOnly={this.state.disabled}
								style={{ border: this.state.disabled ? 0 : '1px solid #efefef' }}
								onChange={(e) => this.setState({ cancelRmk: e.target.value })}
								value={this.state.cancelRmk}
							/>
						</td>
					</tr>
				</>
			);
		} else {
			return (
				<tr>
					<th>반납취소사유</th>
					<td colSpan="3">
						<input
							type="text"
							readOnly={this.state.disabled}
							style={{ border: this.state.disabled ? 0 : '1px solid #efefef' }}
							onChange={(e) => this.setState({ cancelRmk: e.target.value })}
							value={this.state.cancelRmk}
						/>
					</td>
				</tr>
			);
		}
	};



	render() {
		let appInfoStyle = {
			textAlign: 'center',
			border: 0,
		};

		return (
			<div>
				<Header />
				<div className="container" id="container">
					<div className="inner">
						<div className="leftMenu">
							<LeftMenu />
						</div>
						<div className="rightCont">
							<div className="h2Wrap mb30">
								<h2>반납신청조회</h2>
								<div className="btnRight">
									<a className="btn01 search" onClick={this.getReturnAskList}>
										<span>조회</span>
									</a>
								</div>
							</div>

							<div className="writeType03">
								<table>
									<colgroup>
										<col style={{ width: '90px' }} />
										<col style={{ width: '280px' }} />
										<col style={{ width: '90px' }} />
										<col style={{ width: '150px' }} />
										<col style={{ width: '90px' }} />
										<col />
									</colgroup>
									<tbody>
										<tr>
											<th>고객명</th>
											<td>
												<input type="text" readOnly={true} style={{ marginRight: 10, width: 220 }} value={this.state.sCustNm} />
												<IconButton onClick={this.customerSearch} color="secondary" className="iconButton" style={{ display: sessionStorage.btnControl }}>
													<SearchIcon fontSize="small" />
												</IconButton>
											</td>
											<th>반납상태</th>
											<td>
												<select style={{ width: 120 }} onChange={(e) => this.setState({ sReturnStatus: e.target.value })}>
													<option value="">전체</option>
													{this.state.returnStatusList.map((item) => (
														<option value={item.value}>{item.title}</option>
													))}
												</select>
											</td>
											<th>자산번호</th>
											<td>
												<input
													type="text"
													style={{ marginRight: 10, width: 120 }}
													value={this.state.sAsstNo}
													onChange={(e) => this.setState({ sAsstNo: e.target.value })}
												/>
											</td>
										</tr>
										{this.makeUserChgInput()}
									</tbody>
								</table>
							</div>
							<div className="writeType03" style={{ float: 'left', width: '37%' }} onClick={this.getReturnAskData}>
								<div
									className="scroll"
									onMouseOver={() => {
										document.body.style.overflow = 'hidden';
									}}
									onMouseOut={() => {
										document.body.style.overflow = 'auto';
									}}
								>
									<p className="headline">반납요청리스트</p>
									<IBSheet {...this.sheetData} />
								</div>
							</div>
							<div style={{ float: 'right', width: '58%', height: '600px' }}>
								<p className="headline">요청정보</p>
								<div className="writeType03_noborder">
									<table>
										<colgroup>
											<col style={{ width: 100 }} />
											<col style={{ width: 200 }} />
											<col style={{ width: 90 }} />
											<col style={{ width: 160 }} />
										</colgroup>
										<tbody>
											<tr>
												<th>고객명</th>
												<td>
													<input type="text" readOnly={true} value={this.state.custNm} />
												</td>
												<th style={{ color: '#E91E63' }}>위약금</th>
												<td>{this.state.cancelFee ? <input type="text" readOnly={true} value={InsertComma(this.state.cancelFee) + '원'} /> : ''}</td>
											</tr>
											<tr>
												<th>자산번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.viewAsstNo} />
												</td>
												<th>계약번호</th>
												<td>
													<input type="text" readOnly={true} value={this.state.contNo} />
												</td>
											</tr>
											<tr>
												<th style={{ color: '#E91E63' }}>반납상태</th>
												<td>
													<input type="text" readOnly={true} value={this.state.returnStatus} />
												</td>
												<th>회수희망일자</th>
												<td>
													<input type="text" readOnly={true} value={dateToFormat(this.state.returnHopeDate)} />
												</td>
											</tr>
											<tr>
												<th>반납사유</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.returnRmk} />
												</td>
											</tr>
											<tr>
												<th>회수지주소</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.postAddr + ' ' + this.state.postSddr} />
												</td>
											</tr>
											<tr>
												<th>담당자</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.addrUser + ' ( ' + this.state.addrTelno + ' / ' + this.state.addrHpno + ' )'} />
												</td>
											</tr>
											<tr>
												<th>회수요청사항</th>
												<td colSpan="3">
													<input type="text" readOnly={true} value={this.state.delRmk} />
												</td>
											</tr>
											{this.changeReturnReasonInput(this.state.returnStatusCd)}
										</tbody>
									</table>
								</div>
								<div style={{ float: 'right' }}>
									<Button
										color="secondary"
										variant="contained"
										size="small"
										style={{ alignItems: 'initial' }}
										onClick={this.cancelHandle}
										disabled={this.state.disabled}
									>
										<CancelIcon style={{ fontSize: 20, marginRight: 5 }} />
										반납취소
									</Button>
								</div>
								<div className="writeType03" style={{ marginTop: 50 }}>
									<p className="headline">결재정보</p>
									<table>
										<colgroup>
											<col style={{ width: '70px' }} />
											<col style={{ width: '90px' }} />
											<col style={{ width: '70px' }} />
											<col style={{ width: '110px' }} />
											<col style={{ width: '200px' }} />
										</colgroup>
										<tbody>
											<tr style={{ height: 40 }}>
												<th style={{ textAlign: 'center' }}>결재순서</th>
												<th style={{ textAlign: 'center' }}>결재자</th>
												<th style={{ textAlign: 'center' }}>결재여부</th>
												<th style={{ textAlign: 'center' }}>결재일자</th>
												<th style={{ textAlign: 'center' }}>결재비고</th>
											</tr>
											{this.state.appList.length > 0 ? (
												this.state.appList.map((item, i) => (
													<tr style={{ textAlign: 'center' }}>
														<td>
															<input type="text" className="txtCenter" value={i + 1} readOnly={true} style={appInfoStyle} />
														</td>
														<td>
															<input type="text" className="txtCenter" value={item.userName} readOnly={true} style={appInfoStyle} />
														</td>
														<td>
															<input type="text" className="txtCenter" value={item.appYn} readOnly={true} style={appInfoStyle} />
														</td>
														<td>
															<input
																type="text"
																className="txtCenter"
																value={item.appDate ? dateToFormat(item.appDate) : ''}
																readOnly={true}
																style={appInfoStyle}
															/>
														</td>
														<td>
															<Tooltip title={item.appDesc} placement="top">
																<input type="text" className="txtCenter" value={item.appDesc} readOnly={true} style={appInfoStyle} />
															</Tooltip>
														</td>
													</tr>
												))
											) : (
												<tr style={{ textAlign: 'center' }}>
													<td colSpan="5">등록된 결재 정보가 없습니다.</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								<div style={{ marginTop: 55, display: sessionStorage.authCode < '2' ? 'inline-block' : 'none' }}>
									<p className="headline">회수정보</p>
									<div className="writeType03_acc">
										<table>
											<colgroup>
												<col style={{ width: 100 }} />
												<col style={{ width: 120 }} />
												<col style={{ width: 100 }} />
												<col style={{ width: 220 }} />
											</colgroup>
											<tbody>
												<tr>
													<th>회수방법</th>
													<td>
														<select value={this.state.delMethod} onChange={(e) => this.setState({ delMethod: e.target.value })} disabled={this.state.disabled}>
															<option value="">선택</option>
															{this.state.delMethodList.map((item) => (
																<option value={item.value}>{item.title}</option>
															))}
														</select>
													</td>
													<th>회수일자</th>
													<td>
														{this.state.returnStatusCd === '1' ? (
															<DatePicker
																customInput={<input type="text" style={{ width: 120 }} />}
																dateFormat="YYYY-MM-DD"
																onChange={(date) => this.dateHandler(date, 's')}
																selected={this.state.delDate}
																isClearable={!this.state.disabled}
																disabled={this.state.disabled}
																showMonthDropdown
																showYearDropdown
																dropdownMode="select"
															/>
														) : (
															<input type="text" readOnly={true} style={{ width: 120 }} value={dateToFormat(this.state.delRealDate)} />
														)}
														<select
															value={this.state.delTimeClas}
															style={{ width: 90, marginTop: 5 }}
															onChange={(e) => {
																this.setState({delTimeClas: e.target.value});
																if(e.target.value != '04') this.state.delTime = '';
															}}
															disabled={this.state.disabled}
														>
															<option value="">선택</option>
															<option value="01">오전</option>
															<option value="02">오후</option>
															<option value="03">종일</option>
															<option value="04">시간지정</option>
														</select>
														<select
															value={this.state.delTime}
															style={{ width: 70, marginLeft: 5 }}
															onChange={(e) => this.setState({ delTime: e.target.value })}
															disabled={this.state.disabled || this.state.delTimeClas != '04'}
														>
															<option value="">선택</option>
															<option value="10">10시</option>
															<option value="11">11시</option>
															<option value="12">12시</option>
															<option value="13">13시</option>
															<option value="14">14시</option>
															<option value="15">15시</option>
															<option value="16">16시</option>
															<option value="17">17시</option>
															<option value="18">18시</option>
														</select>
													</td>
												</tr>
												<tr>
													<th>센터정보</th>
													<td colSpan="3">
														<select value={this.state.centerCd} name="centerCd" onChange={(e) => this.setState({ centerCd: e.target.value })} disabled={this.state.disabled}>
															<option value="">선택</option>
															{this.state.centerCodeList.map((item) => (
																<option value={item.value}>{item.title}</option>
															))}
														</select>
													</td>
												</tr>
												<tr>
													<th>회수요청사항</th>
													<td colSpan="3">
														<input
															type="text"
															value={this.state.askRmk}
															disabled={this.state.disabled}
															onChange={(e) => this.setState({ askRmk: e.target.value })}
														/>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div style={{ float: 'right' }}>
										<span style={{ display: this.state.contInfoBtnShow }}>
											<Button
												color="primary"
												variant="outlined"
												size="small"
												style={{ marginLeft: 10, alignItems: 'initial' }}
												onClick={this.contInfoCheck}
												disabled={this.state.custReqNo ? false : true}
											>
												<CheckCircleOutlineOutlinedIcon style={{ fontSize: 20, marginRight: 5 }} />
												계약정보 확인
											</Button>
										</span>
										<span style={{ display: this.state.custNo == '3148330' ? 'inline' : 'none' }}>
											<Button
												color="primary"
												variant="contained"
												size="small"
												style={{ marginLeft: 10, alignItems: 'initial' }}
												onClick={this.returnAskHandle}
												disabled={this.state.disabled}
											>
												<ReturnIcon style={{ fontSize: 20, marginRight: 5 }} />
												회수요청
											</Button>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default observer(ReturnAsk);
