import React, { Component } from 'react';
import { getTxCnfmInfo, postTxCnfmInfo, getTxCnfmInfoCnt } from '../../service/erp/TxCnfmSmsService';
import { errorMsgCheck, isEmpty, telValidator, isEmptyObject } from '../../common/ComFunction';
import SignaturePad from 'signature_pad';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

//[대응개발] 2024-04-19 이미지사용 by choye START
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown"; 
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp"; 
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
//[대응개발] 2024-04-19 이미지사용 by choye END

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

let sigPad = null;

export default class TxCnfmSms extends Component {
	constructor(props) {
		super(props);
		this.state = {
			width: 0,
			height: 0,
			minWidth: 0, //Iphone 10 기준 가로길이
			signWidth: 290, // 서명 가로px
			signHeight: 92, // 서명 세로px

			delInsuMst: new Object(), // 인수인도서 마스터정보
			custInfo: new Object(), // 인수인도서 고객정보
			delInsuAsstList: [], // 인수인도서 자산 리스트

			idNo: '', // 사업자번호
			idNoCheck: false, // 고객정보의 사업자번호 체크여부
			sigPadData: null, // 인수인 서명 데이터
			insuPhoneNo: '', // 인수인 연락처 입력값
			agentRcvCheck: false, // 대리인 수령여부
			agentRelation: '', // 대리인 관계

			loading: false,
			success: true,
			navValue: null //[대응개발] 2024-04-19 네비게이션바 값 추가 by choye
		};
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this); // 접속기기 가로/세로 길이
	}
	
	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight, minWidth: window.innerWidth - 10 > 375 ? 375 : window.innerWidth - 10 });
	}

	componentDidMount = () => {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions); // 크기 변경시 가로/세로 길이 재설정 이벤트 바인딩

		this.txCnfmInfo();
	};

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions); // 크기 변경시 가로/세로 길이 재설정 이벤트 바인딩 제거
	}

	// 인수인도서 정보 조회
	txCnfmInfo = () => {
		let delAskNo = this.props.match.params.delAskNo;
		const noInsuInfoMsg = '[AJ렌탈]\n존재하지않는 거래확인서 정보입니다.\n문의전화 : 1588-0053';
		const yesInsuInfoMsg = '[AJ렌탈]\n이미 확인 후 서명하신 거래확인서입니다.\n문의전화 : 1588-0053';
		//[대응개발] 2024-04-18 메세지 추가 by choye START
		const notInsuInfoMsg = '[AJ렌탈]\n서명할 수 없는 거래확인서입니다.\n문의전화 : 1588-0053';
		//[대응개발] 2024-04-25 메세지 추가 by choye START
		const chkInsuInfoMsg = '[AJ렌탈]\n서명 요청중인 거래확인서입니다.\n문의전화 : 1588-0053';

		if (delAskNo !== undefined && delAskNo !== null && delAskNo !== '') {
			getTxCnfmInfo(delAskNo)
				.then((r) => {
					// console.log(isEmptyObject(this.state.delInsuMst));
					if (r.length > 0) {
						if (r[0] && r[0].delInsuMst) {

							if(r[0].delInsuMst.delStatus == '2'){
								if(r[0].delInsuMst.insuChk == 'Y'){
									alert(chkInsuInfoMsg);
								}else{
									this.setState({
										delInsuMst: r[0].delInsuMst,
										custInfo: r[0].custInfoForInsu,
										delInsuAsstList: r[0].delInsuAsstList,
									});
								}
							}else if(r[0].delInsuMst.delStatus == '4'){
								alert(yesInsuInfoMsg);
							}else{
								alert(notInsuInfoMsg);
							}
							//[대응개중] 2024-04-18 메세지 체크조건변경 by choye END
						} else {
							alert(noInsuInfoMsg);
						}
					} else {
						alert(noInsuInfoMsg);
					}
				})
				.catch((err) => {
					errorMsgCheck(err);
				});
		} else {
			alert('잘못된 파라미터입니다.');
		}
	};

	// 인수인정보/서명 등록
	postTxCnfmInfo = () => {
		let param = {
			P_DEL_ASK_NO: this.props.match.params.delAskNo,
			hpno: this.state.insuPhoneNo,
			SIGN_DATA: sigPad.toDataURL(),
			AGENT_RCV_YN: this.state.agentRcvCheck ? 'Y' : 'N',
			AGENT_RELATION: this.state.agentRelation,
		};
		let delAskNo = this.props.match.params.delAskNo;

		this.setState({
			success: false,
			loading: true,
		});

		//[대응개발] 2024-04-22 저장 로직 변경 by choye START
		postTxCnfmInfo(param)
			.then((r) => {
				let errMsg = r[0].errMsg;

				if (errMsg !== '') {
					alert(errMsg);
				} else {
					alert('저장되었습니다.');
				}
				location.reload();
				//[대응개발] 2024-04-22 저장 로직 변경 by choye END
			})
			.catch((err) => {
				errorMsgCheck(err);
			})
			.finally(() => {
				this.setState({
					success: true,
					loading: false,
				});
			});

	};

	//자산리스트
	drawDelInsuAsstListForm = () => {
		const { delInsuAsstList, minWidth } = this.state;
		const rows = [];
		delInsuAsstList.map((v, i) => {
			v.no = i + 1;

			let fromDate = new Date(v.fromDate);
			let toDate = new Date(v.toDate);

			v.fromDate = fromDate.getFullYear() + '-' + (fromDate.getMonth() + 1) + '-' + fromDate.getDate();
			v.toDate = toDate.getFullYear() + '-' + (toDate.getMonth() + 1) + '-' + toDate.getDate();
			rows.push(v);
		});

		return (
			<TableContainer component={Paper}>
				<Table stickyHeader sx={{ minWidth: minWidth }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center" colSpan={2}>
								렌탈물건 세부현황 (단위: 원, VAT별도)
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.item1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row">
									{row.no}
								</TableCell>
								<TableCell>
									<Table stickyHeader aria-label="simple table">
										<TableBody>
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell component="th" scope="row" align="left" sx={{ fontWeight: 'medium' }}>
													제품명/모델
												</TableCell>
												<TableCell align="right">
													{row.itemNm} / {row.model}
												</TableCell>
											</TableRow>
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell component="th" scope="row" align="left" sx={{ fontWeight: 'medium' }}>
													자산번호/시리얼
												</TableCell>
												<TableCell align="right">
													{row.asstNo} / {row.serialNo}
												</TableCell>
											</TableRow>
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell component="th" scope="row" align="left" sx={{ fontWeight: 'medium' }}>
													개시일
												</TableCell>
												<TableCell align="right">{row.fromDate}</TableCell>
											</TableRow>
											<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell component="th" scope="row" align="left" sx={{ fontWeight: 'medium' }}>
													만료일
												</TableCell>
												<TableCell align="right">{row.toDate}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	//마스터정보
	drawDelInsuMstForm = () => {
		const { delInsuMst, minWidth } = this.state;
		const startDate = new Date(delInsuMst.startDate);
		const endDate = new Date(delInsuMst.endDate);
		const rows1 = [
			this.createData('수신', 'AJ네트웍스(주)', '회사명', delInsuMst.custNm),
			this.createData('참조', `${delInsuMst.userName} ${delInsuMst.email}`, '주소', delInsuMst.reqAddr),
			this.createData('연락처', `${delInsuMst.mobilNo} FAX ${delInsuMst.faxNo}`, '대표이사', delInsuMst.ceoNm),
		];
		const rows2 = [
			this.createData('계약번호', delInsuMst.contNo),
			this.createData('렌탈개시일', startDate.getFullYear() + '-' + (startDate.getMonth() + 1) + '-' + startDate.getDate()),
			this.createData('렌탈종료일', endDate.getFullYear() + '-' + (endDate.getMonth() + 1) + '-' + endDate.getDate()),
			this.createData('렌탈기간', delInsuMst.렌탈기간),
		];
		return (
			<TableContainer component={Paper}>
				<Table stickyHeader sx={{ minWidth: minWidth }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center" sx={{ fontSize: 20, fontWeight: 'medium' }} colSpan={4}>
								인 수 인 도 확 인 서
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell colSpan={2} sx={{ fontWeight: 'medium' }}>
								렌탈회사
							</TableCell>
							{/* <TableCell align="right"></TableCell> */}
							<TableCell colSpan={2} sx={{ fontWeight: 'medium' }}>
								렌탈이용자
							</TableCell>
							{/* <TableCell align="right"></TableCell> */}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows1.map((row) => (
							<TableRow key={row.item1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
									{row.item1}
								</TableCell>
								<TableCell align="right">{row.content1}</TableCell>
								<TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
									{row.item2}
								</TableCell>
								<TableCell align="right">{row.content2}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<Table stickyHeader sx={{ minWidth: minWidth }} aria-label="simple table">
					<TableBody>
						{rows2.map((row) => (
							<TableRow key={row.item1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
									{row.item1}
								</TableCell>
								<TableCell align="right">{row.content1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	//기타정보
	drawDelInsuEtcForm = () => {
		const { delInsuMst, minWidth } = this.state;
		const rows = [
			this.createData('설치장소', delInsuMst.location),
			this.createData('렌탈료 납입조건', delInsuMst.납입조건),
			this.createData('총 렌탈료 납입회차', delInsuMst.납입회차),
		];
		return (
			<TableContainer component={Paper}>
				<Table stickyHeader sx={{ minWidth: minWidth }} aria-label="simple table">
					<TableBody>
						{rows.map((row) => (
							<TableRow key={row.item1} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
								<TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
									{row.item1}
								</TableCell>
								<TableCell align="right">{row.content1}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	handleChange = (e, i) => {
		// console.log(e.target.value);
		if (i && isNaN(e.target.value)) {
			console.log('nan');
		} else {
			this.setState({
				[e.target.name]: i ? e.target.value.slice(0, i) : e.target.type === 'checkbox' ? e.target.checked : e.target.value,
			});
		}
	};

	// 서명란
	drawDelInsuSignForm = () => {
		const { minWidth } = this.state;
		return (
			<TableContainer component={Paper}>
				<Table stickyHeader sx={{ minWidth: minWidth }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="center" sx={{ fontWeight: 'medium' }} colSpan={2}>
								인수인 서명
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
								대리인
							</TableCell>
							<TableCell align="left">
								<Checkbox name="agentRcvCheck" checked={this.state.agentRcvCheck} onChange={(e) => this.handleChange(e)} />
							</TableCell>
						</TableRow>
						<TableRow sx={{ display: this.state.agentRcvCheck ? 'table-row' : 'none', '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
								관계
							</TableCell>
							<TableCell align="left">
								<TextField
									size="small"
									required
									name="agentRelation"
									label="대리수령인 관계"
									value={this.state.agentRelation}
									// onChange={this.handleChange}
									onInput={(e) => this.handleChange(e)}
									inputProps={{ maxLength: 6 }}
								/>
							</TableCell>
						</TableRow>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
								연락처
							</TableCell>
							<TableCell align="left">
								<TextField
									size="small"
									required
									name="insuPhoneNo"
									label="인수인 연락처"
									value={this.state.insuPhoneNo}
									// onChange={this.handleChange}
									onInput={(e) => this.handleChange(e, 11)}
									inputProps={{ maxLength: 12, inputMode: 'numeric', pattern: '[0-9]*' }}
									helperText="'-'나 공백없이 숫자만 입력해주세요"
								/>
							</TableCell>
						</TableRow>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell component="th" scope="row" sx={{ fontWeight: 'medium' }}>
								서명란
							</TableCell>
							<TableCell align="left">
								<Box className="Signature" sx={{ width: this.state.signWidth, height: this.state.signHeight, border: '1px dashed grey' }}>
									<canvas id="signature-pad" className="signature-pad" width={this.state.signWidth} height={this.state.signHeight} />
								</Box>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		);
	};

	//버튼구역
	drawDelInsuBtnForm = () => {
		const { minWidth } = this.state;

		return (
			<TableContainer component={Paper}>
				<Table stickyHeader sx={{ minWidth: minWidth }} aria-label="simple table">
					<TableBody>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell align="center">
								<Button variant="outlined" size="small" color="error" onClick={this.handleRestSignature}>
									리셋
								</Button>
							</TableCell>
							<TableCell align="center">
								<Box sx={{ m: 1, position: 'relative' }}>
									<Button variant="outlined" size="small" onClick={this.submit} disabled={this.state.loading}>
										저장
									</Button>
									{this.state.loading && (
										<CircularProgress
											size={24}
											sx={{
												color: green[500],
												position: 'absolute',
												top: '50%',
												left: '50%',
												marginTop: '-12px',
												marginLeft: '-12px',
											}}
										/>
									)}
								</Box>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		);
	};
	
	//[대응개발] 2024-04-19 네비게이션바 사이즈 만큼 HEIGHT 추가 by choye START
	drawDelInsuBlkForm = () => {
		const { minWidth } = this.state;

		return (
			<TableContainer component={Paper}>
				<Table stickyHeader sx={{ minWidth: minWidth, height : 50 }} aria-label="simple table">
					<TableBody>
						<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
							<TableCell align="center">
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		);
	};
	//[대응개발] 2024-04-19 네비게이션바 사이즈 만큼 HEIGHT 추가 by choye END

	createData = (item1, content1, item2, content2) => {
		if (item2) {
			return { item1, content1, item2, content2 };
		} else {
			return { item1, content1 };
		}
	};

	handleRestSignature = () => {
		sigPad.clear();
		this.setState({
			sigPadData: null,
		});
	};

	submit = () => {
		// console.log(this.state.sigPadData);
		// console.log(sigPad.isEmpty());
		// console.log(sigPad.toData());
		console.log(sigPad.toDataURL());
		// 저장전 연락처/ 서명입력여부 확인
		if (isEmpty(this.state.insuPhoneNo)) {
			alert('인수인 연락처는 필수 입력입니다.');
			return;
		}
		if (!telValidator(this.state.insuPhoneNo)) {
			alert('인수인 연락처 형식이 올바르지 않습니다.');
			return;
		}
		if (sigPad.isEmpty()) {
			alert('인수인 서명은 필수입니다.');
			return;
		}
		if (this.state.agentRcvCheck && isEmpty(this.state.agentRelation)) {
			alert('대리인 관계를 입력해주세요.');
			return;
		}

		if (confirm('서명을 전송하시겠습니까?')) {
			this.postTxCnfmInfo();
		}
	};

	compareIdNo = () => {
		// 사업자번호 체크 호출
		if (this.state.idNo === this.state.custInfo.idNo) {
			this.setState({ idNoCheck: true });
			this.initSignPad();
		} else {
			alert('[AJ렌탈]\n해당 인수인도서의 고객사 사업자번호가 아닙니다.\n문의전화 : 1588-0053');
		}
	};

	initSignPad = () => {
		// sign pad는 실제 인수인도서 정보 존재시 초기화!
		// console.log(document.querySelector('canvas'));
		const interval = setInterval(() => {
			if (document.querySelector('canvas')) {
				sigPad = new SignaturePad(document.querySelector('canvas'), {
					onBegin: () => {
						// setSigPadData(sigPad.toDataURL()); // data:image/png;base64,iVBORw0K...
						this.setState({
							sigPadData: sigPad.toDataURL(),
						});
						/**
						 * signaturePad.toDataURL(); // save image as PNG
						 * signaturePad.toDataURL("image/jpeg"); // save image as JPEG
						 * signaturePad.toDataURL("image/svg+xml"); // save image as SVG
						 * */
					},
				});

				clearInterval(interval);
			}
		}, 100);
	};

	render() {
		if (isEmptyObject(this.state.delInsuMst)) {
			// 마스터정보 여부로 조회판별
			return <div></div>;
		} else {
			return (
				// 인수인도서 정보 존재
				<div>
					{this.state.idNoCheck ? (
						<div>
							{/* 마스터정보 */}
							{this.drawDelInsuMstForm()}
							{/* 자산리스트 */}
							{this.drawDelInsuAsstListForm()}
							{/* 기타정보 */}
							{this.drawDelInsuEtcForm()}
							{/* 서명란 */}
							{this.drawDelInsuSignForm()}
							{/* 버튼구역 */}
							{this.drawDelInsuBtnForm()}
							{/* [대응개발] 2024-04-19 네비게이션바(위로, 아래로) 추가 by choye START */}
							{this.drawDelInsuBlkForm()}
							<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
								<BottomNavigation
								  showLabels
								  value={this.state.navValue}
								  onChange={(event, newValue) => {
								    //setValue(newValue);
								    //alert(newValue);
								    if(newValue == 0){
										//up
										//if (!window.scrollY) return;

									    window.scrollTo({
									      top: 0,
									      behavior: 'smooth',
									    });
									}else if(newValue == 1){
										//down
										//if (!window.scrollY) return;

									    window.scrollTo({
									      top: document.documentElement.scrollHeight - document.documentElement.clientHeight,
									      behavior: 'smooth',
									    });
									}
								  }}
								>
								  <BottomNavigationAction label="위로" icon={<ArrowUpIcon />} />
								  <BottomNavigationAction label="아래로" icon={<ArrowDownIcon />} />
								</BottomNavigation>
							</Paper>
							{/* [대응개발] 2024-04-19 네비게이션바(위로, 아래로) 추가 by choye END */}
						</div>
					) : (
						<Box
							sx={{
								width: this.state.width,
								height: this.state.height,
								backgroundColor: '#ffffff',
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
							}}
						>
							<Box
								sx={{
									p: 2,
									border: '1px dashed grey',
									width: this.state.minWidth,
									height: 200,
									backgroundColor: '#ffffff',
								}}
							>
								<TextField
									required
									name="idNo"
									label="사업자번호"
									value={this.state.idNo}
									onInput={(e) => this.handleChange(e, 10)}
									inputProps={{ maxLength: 12, inputMode: 'numeric', pattern: '[0-9]*' }}
									helperText="인수인도서 확인을 위해 고객사의 사업자번호(10자리)를 입력해주세요"
								/>
								<div style={{ marginTop: 20, display: 'flex', justifyContent: 'flex-end' }}>
									<Button variant="outlined" size="small" onClick={this.compareIdNo}>
										확인
									</Button>
								</div>
							</Box>
						</Box>
					)}
				</div>
			);
		}
	}
}
