import { httpGet, httpPost, httpPut, toQueryString } from '../Service';

//렌탈신청
export function insertRentOrder(param) {
	return httpGet('/api/v1.0/cis/rental/order' + param);
}
//결재자 리스트
export function getCustAppUserList(param) {
	return httpGet('/api/v1.0/cis/rental/order/app-user-list' + toQueryString(param));
}

//제품 리스트
export function getCustOrderItemList(param) {
	return httpGet('/api/v1.0/cis/rental/order/item-list' + toQueryString(param));
}

//요청리스트 가져오기
export function getOrderList(data) {
	return httpGet('/api/v1.0/cis/rental/order-list' + toQueryString(data));
}

//요청리스트(excel용) 가져오기
export function getOrderListForExcel(data) {
	return httpGet('/api/v1.0/cis/rental/order-list/excel' + toQueryString(data));
}

//요청상세정보 가져오기
export function getOrderDetail(ordeNo) {
	return httpGet('/api/v1.0/cis/rental/order-list/' + ordeNo);
}

//결재상태 가져오기
export function getOrderAppStatus(ordeNo) {
	return httpGet('/api/v1.0/cis/rental/order-list/app/' + ordeNo);
}

//배송상태 가져오기
export function getOrderDeliveryStatus(ordeNo) {
	return httpGet('/api/v1.0/cis/rental/order-list/delivery/' + ordeNo);
}

//신청정보 업데이트
export function updateRentOrder(data) {
	return httpGet('/api/v1.0/cis/rental/order/update' + data);
}

//신청제품 리스트 가져오기
export function getOrderItemList(ordeNo) {
	return httpGet('/api/v1.0/cis/rental/order-list/item/' + ordeNo);
}

//신청취소
export function cancelRentOrder(data) {
	data = data || {};
	return httpPut('/api/v1.0/cis/rental/order/' + data.ordeNo, { body: data });
}

//엑셀발주
export function insertExcelRentOrder(data, param) {
	data = data || {};
	return httpPost('/api/v1.0/cis/rental/excel-order' + toQueryString(param), { body: data });
}

// 엑셀대량발주 권한체크
export function getBigOrderCheck(data) {
	return httpGet('/api/v1.0/cis/rental/bigOrderCheck' + toQueryString(data));
}

//요청제품 조합 리스트 가져오기
export function getOrderItemCombList(itemSeq) {
	return httpGet('/api/v1.0/cis/rental/order-list/item/comb/' + itemSeq);
}

//재약정 고객요청번호 변경
export function updateCustReqNo(data) {
	data = data || {};
	console.log(data);
	return httpPut('/api/v1.0/cis/rental/order/renewal/', { body: data });
}

// 주문동기화
export function SyncOrder(data) {
	data = data || {};
	console.log(data);
	return httpPut('/api/v1.0/cis/rental/order/sync/', { body: data });
}
