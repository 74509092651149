import React, { Component } from 'react';
import { makeObservable, observable, action } from 'mobx';
import { observer } from 'mobx-react';

const SyncOrderPopupStore = observable({
	open: false,
	contNo: '',
	ordeNo: '',

	openPopup(ordeNo) {
		this.open = true;
		this.ordeNo = ordeNo;
	},
	closePopup() {
		this.open = false;
		this.contNo = '';
	},
	setContNo(contNo) {
		this.contNo = contNo;
	},
});

export default SyncOrderPopupStore;
